import React from 'react';
import H3 from '../typography/H3';
import P from '../typography/P';

const SvgCard = ({ title, text, image }) => {
  return (
    <div className="bg-white p-5 shadow-lg">
      <div>{image}</div>
      <div className="text-center">
        <H3 className="mb-4">{title}</H3>
        <P>{text}</P>
      </div>
    </div>
  );
};

export default SvgCard;
