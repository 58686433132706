import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';

const FaqItem = ({ title, text }) => {
  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full rounded-xl border bg-white p-4 font-display text-lg font-bold">
            <div className="flex items-center justify-between">
              <dt className="text-left">{title}</dt>
              <div>
                <ChevronRightIcon className={`h-4 ${open ? 'rotate-90 transform' : ''}`} />
              </div>
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100  translate-y-0"
            leaveTo="opacity-0 -translate-y-4"
            unmount={false}
          >
            <Disclosure.Panel as="dd" static className="max-w-full p-4" unmount={false}>
              {text}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default FaqItem;
